<template>
  <div>
    <el-form
      v-model="placementMsg"
      label-width="120px"
    >
      <el-form-item label="版位：">
        <el-radio-group v-model="placementType">
          <el-radio :label="1">进阶版位</el-radio>
          <el-radio :label="2">选择版位</el-radio>
        </el-radio-group>
      </el-form-item>
      <span v-if="placementType == 2">
        <el-form-item label="设备类型：">
          <el-select
            v-model="placementMsg.device_platforms"
            placeholder="请选择"
            multiple
            @change="selectPlacement"
          >
            <el-option
              :label="item.desc"
              :value="item.key"
              v-for="item in platForm"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div>
            <div class="tree">
              <el-tree
                :data="platFormList.filter((v) => v.key == treeItem.key)"
                show-checkbox
                default-expand-all
                node-key="key"
                :props="defaultProps"
                :default-checked-keys="checkedNodes[treeItem.key + '_positions']"
                v-for="treeItem in platFormList"
                :key="treeItem.key"
                ref="plateTree"
                @check="checkChange"
              >
              </el-tree>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="移动设备："
          v-if="placementMsg.device_platforms.includes('mobile')"
        >
          <el-select
            v-model="placementMsg.user_os"
            placeholder="请选择"
            @change="selectDevice"
          >
            <el-option
              :label="item.desc"
              :value="item.key"
              v-for="item in userOsList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="设备机型"> -->
        <span v-if="placementMsg.device_platforms.includes('mobile') && placementMsg.user_os != 'All'">
          <selectTag
            :data="placementList"
            :params="{ id: 'id', name: 'description', children: 'childList' }"
            keys="model"
            label="设备机型："
            place="请选择设备机型"
            :list="placementMsg.user_device"
            :listDefault="placementMsg.user_device"
            @setList="setList"
          ></selectTag>
        </span>

        <!-- </el-form-item> -->
      </span>
    </el-form>
    <!-- <div class="topPreviw">
                <div class="top-radio">
                    <el-radio v-model="radio" label="1" >自动版位</el-radio>
                    <el-radio v-model="radio" label="2" >选择版位</el-radio>
                </div>
                <div v-if="radio == 2">
                    <el-select v-model="device_platforms" placeholder="" @change="selectPlacement" multiple>
                        <el-option :label="item.desc" :value="item.key"  v-for="(item) in initData.placement.DevicePlatform" :key="item.key"></el-option>
                    </el-select>
                    <div class="tree" v-if="initData.placement">
                        <el-tree
                        :data="platFormList.filter(v=>v.key == treeItem.key)"
                        show-checkbox
                        default-expand-all
                        node-key="key"
                        :props="defaultProps"
                        :default-checked-keys="checkedNodes[treeItem.key+'_positions']"
                        v-for="treeItem in platFormList" :key="treeItem.key"
                        ref="plateTree">
                        </el-tree>
                    </div>
                </div>
            </div> -->
  </div>
</template>
<script>
const placementListAll = require('@/assets/json/placement.json');
import selectTag from './selectTag.vue';
export default {
  props: {
    placement: {
      type: Object,
      default: () => {},
    },
    initData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    selectTag,
  },
  data() {
    return {
      device_platforms: [],
      defaultProps: {
        children: 'positions',
        label: 'desc',
      },
    };
  },
  computed: {
    placementType: {
      get() {
        if (this.placement.device_platforms && this.placement.device_platforms.length) {
          return 2;
        } else {
          return 1;
        }
      },
      set(v) {
        this.selectType(v);
      },
    },
    placementList: {
      get() {
        if (this.placementMsg.user_os == 'iOS') {
          return placementListAll.filter((v) => v.platform == '2');
        } else if (this.placementMsg.user_os == 'Android') {
          return placementListAll.filter((v) => v.platform == '1');
        } else {
          return placementListAll.filter((v) => v.platform !== '3');
        }
      },
    },
    listDefault: {
      get() {
        console.log(this.placementMsg.user_os);
        if (this.placementMsg.user_os == 'iOS') {
          this.placementMsg.user_device =
            this.placementMsg.user_device || placementListAll.filter((v) => v.platform == '2').map((v) => v.id);
        } else if (this.placementMsg.user_os == 'Android') {
          this.placementMsg.user_device =
            this.placementMsg.user_device || placementListAll.filter((v) => v.platform == '1').map((v) => v.id);
        } else {
          this.placementMsg.user_device =
            this.placementMsg.user_device || placementListAll.filter((v) => v.platform !== '3').map((v) => v.id);
        }
        return this.placementMsg.user_device;
      },
    },
    userOsList: {
      get() {
        let list = [];
        this.placementMsg.device_platforms.map((key) => {
          list = list.concat(this.platForm.filter((v) => v.key == key)[0].userOsList || []);
        });
        return list;
      },
    },
    checkedNodes: {
      get() {
        let obj = {
          facebook_positions: [],
          instagram_positions: [],
          audience_network_positions: [],
          messenger_positions: [],
        };
        obj.messenger_positions = this.placement.messenger_positions || [];
        obj.facebook_positions = this.placement.facebook_positions || [];
        obj.audience_network_positions = this.placement.audience_network_positions || [];
        obj.instagram_positions = this.placement.instagram_positions || [];
        return obj;
      },
      set() {},
    },
    placementMsg: {
      get() {
        if (this.placement && Object.keys(this.placement).length) {
          if (this.placement.user_os && Array.isArray(this.placement.user_os) && this.placement.user_os.length) {
            this.placement.user_os = this.placement.user_os[0];
          } else {
            this.placement.user_os = this.placement.user_os || 'All';
          }

          return this.placement;
        } else {
          return {
            device_platforms: [],
          };
        }
      },
      set() {},
    },
    platForm: {
      get() {
        return this.initData?.placement.DevicePlatform || [];
      },
    },
    platFormList: {
      get() {
        let data = JSON.parse(JSON.stringify(this.initData.placement.PublisherPlatform));
        if (!this.placementMsg.device_platforms || !this.placementMsg.device_platforms.length) {
          data.map((key, i) => {
            key.disabled = true;
            key.positions.map((item, m) => {
              item.disabled = true;
              this.$set(key.positions, m, item);
            });
          });
          return data;
        }
        console.log(this.checkedNodes.facebook_positions);
        let list = this.checkedNodes.facebook_positions
          .concat(this.checkedNodes.messenger_positions)
          .concat(this.checkedNodes.audience_network_positions)
          .concat(this.checkedNodes.instagram_positions);
        let list1 = [
          this.checkedNodes.facebook_positions || [],
          this.checkedNodes.messenger_position || [],
          this.checkedNodes.audience_network_positions || [],
          this.checkedNodes.instagram_positions || [],
        ];
        let key1 = '';
        console.log(list1);
        if (list1.filter((v) => v.length).length == 1) {
          if (this.checkedNodes.facebook_positions.length) {
            key1 = 'facebook';
          } else if (this.checkedNodes.messenger_position.length) {
            key1 = 'messenger';
          } else if (this.checkedNodes.audience_network_positions.length) {
            key1 = 'audience_network';
          } else {
            key1 = 'instagram';
          }
        }
        console.log(key1);
        data.map((key, i) => {
          console.log(key);
          if (key.key == key1) {
            key.disabled = true;
          } else {
            key.disabled = false;
          }
        });
        if (list.length == 1) {
          data.map((key, i) => {
            console.log(key);
            if (key.key == key1) {
              key.disabled = true;
            } else {
              key.disabled = false;
            }
            key.positions.map((item, m) => {
              if (item.key == list[0]) {
                console.log(item.key + '=' + list[0].key);
                item.disabled = true;
              } else {
                item.disabled = false;
              }
              this.$set(key.positions, m, item);
            });
            this.$set(data, i, key);
          });
          return data;
        }
        data.map((key, i) => {
          key.positions.map((item, m) => {
            if (item.devicePlatforms.some((v) => this.placementMsg.device_platforms.some((m) => v.key == m))) {
              item.disabled = false;
            } else {
              item.disabled = true;
            }
            this.$set(key.positions, m, item);
          });
          this.$set(data, i, key);
        });
        return data;
      },
    },
    set() {},
  },
  methods: {
    checkChange(a, b, c) {
      this.$nextTick(() => {
        this.emitPlacement();
      });
    },
    // 选择设备
    selectPlacement(val) {
      let data = JSON.parse(JSON.stringify(this.initData.placement.PublisherPlatform));
      data.map((key, i) => {
        key.positions.map((item, m) => {
          if (item.devicePlatforms.some((v) => val.some((m) => v.key == m))) {
            item.disabled = false;
            // console.log(this.checkedNodes[key.key+'_positions']);
            this.checkedNodes[key.key + '_positions'].push(item.key);
          } else {
            item.disabled = true;
          }
          // console.log( this.checkedNodes);
          this.$set(key.positions, m, item);
        });
        this.$set(data, i, key);
        // this.$refs.vuetree.setCheckedNodes(this.platFormList);
      });
      // this.$forceUpdate();
      if (!val.includes('mobile')) {
        this.user_os = 'All';
        this.user_device = [];
      }
      this.emitPlacement();
    },
    // 选择是否是自动版位
    selectType(v) {
      if (v == 1) {
        this.placementMsg.device_platforms = [];
        this.emitPlacement();
      } else {
        this.placementMsg.device_platforms = ['mobile', 'desktop'];
        this.selectPlacement(this.placementMsg.device_platforms);
      }
    },
    // 选择版位
    checkPlate(a, b, c) {
      console.log(a, b, c);
      // this.checkedNodes
    },
    // 获取版位信息
    getPlateform() {
      let palteList = {
        facebook_positions: [],
        instagram_positions: [],
        audience_network_positions: [],
        messenger_positions: [],
      };
      let publisher_platforms = [];
      if (!this.$refs.plateTree) {
        return {
          ...palteList,
          publisher_platforms: publisher_platforms,
        };
      }
      this.$refs.plateTree.map((key, i) => {
        // console.log(key.getCheckedKeys());
        if (i == 0) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('facebook');
            palteList['facebook_positions'] = key.getCheckedKeys().filter((v) => v !== 'facebook');
          }
        }
        if (i == 1) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('instagram');
            palteList['instagram_positions'] = key.getCheckedKeys().filter((v) => v !== 'instagram');
          }
        }
        if (i == 3) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('audience_network');
            palteList['audience_network_positions'] = key.getCheckedKeys().filter((v) => v !== 'audience_network');
          }
        }
        if (i == 2) {
          if (key.getCheckedKeys().length) {
            publisher_platforms.push('messenger');
            palteList['messenger_positions'] = key.getCheckedKeys().filter((v) => v !== 'messenger');
          }
        }
      });
      return {
        ...palteList,
        publisher_platforms: publisher_platforms,
      };
    },
    setList(v) {
      this.placementMsg.user_device = v;
      this.emitPlacement();
    },
    selectDevice(v) {
      if (v == 'iOS') {
        this.placementMsg.user_device = placementListAll.filter((v) => v.platform == '2').map((v) => v.id);
      } else if (v == 'Android') {
        this.placementMsg.user_device = placementListAll.filter((v) => v.platform == '1').map((v) => v.id);
      } else {
        this.placementMsg.user_device = [];
      }
      this.emitPlacement();
    },
    emitPlacement() {
      this.placementMsg.user_os = [this.placementMsg.user_os];
      this.placementMsg.user_device = this.placementMsg.user_device ? this.placementMsg.user_device : [];
      // this.placementMsg = {...this.placementMsg,...this.getPlateform()}
      this.$nextTick(() => {
        this.$emit('getPlacemment', {
          ...this.placementMsg,
          ...this.getPlateform(),
        });
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
