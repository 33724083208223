<template>
  <div class="interesting">
    <el-popover
      placement="bottom-start"
      width="600"
      trigger="click"
      style="width: 800px; max-height: 600px"
      class="locationPopover"
    >
      <div class="treeBox" v-show="!indCodeName">
        <el-tree
          :data="list"
          show-checkbox
          :check-strictly="true"
          :props="{ label: params.name, children: params.children }"
          :node-key="params.id"
          :filter-node-method="filterNode"
          @check="selectChange"
          :expand-on-click-node="false"
          expanded
          ref="tree"
        ></el-tree>
      </div>
      <!-- {{searchList}} -->
      <div class="treeBox searchList" v-show="indCodeName">
        <p v-for="item in searchList" :key="item[params.id]" @click="addKey(item)">{{item[params.name]}}</p>
      </div>
      <el-form-item :label="label" prop="indCode" slot="reference">
        <div class="locations" v-if="checkedList.length">
          <el-tag v-for="tag in checkedList" :key="tag.name" closable @close="deleLocations(tag)">
            {{ tag.name }}
          </el-tag>
          <el-button type="text" size="mini" @click="clearLocation">清除所有</el-button>
        </div>
        <el-input
          v-model="indCodeName"
          @input="filterText"
          style="width: 600px"
          @focus="indTreeFlag = true"
          :placeholder="placeholder"
        ></el-input>
      </el-form-item>
    </el-popover>
    <!-- <el-popover placement="bottom" style="width: 33.33%" width="300px" v-model="mainTreeFlag"> -->
  </div>
</template>

<script>
// import { locations } from "@/api/creatAd";
// const interest = require('@/static/interest.json')
export default {
    props:{
        data:{
            type:Array,
            default:()=>[]
        },
        params:{
            type:Object,
            default:()=>{}
        },
        label:{
            type:String,
            default:''
        },
        place:{
            type:String,
            default:''
        },
        keys:{
            type:String,
            default:''
        },
        listDefault:{
          type:Array,
          default:()=>[]
        }
    },
  data() {
    return {
      mainTreeFlag: null,
      eleIndList: [],
      ruleForm: {},
      indTreeFlag: false,
      interest: [],
      indCodeName: "",
      checkedNodes: [],
      visible:false
    };
  },
  computed: {
    list(){
      console.log(this.data);
        return this.data
    },
    checkedList:{
      get(){
        let selectList = this.checkedNodes.map((v) => {
        return { id: v[this.params.id], name: v[this.params.name], parentId: v.parentId,children:v[this.params.children]};
      });
      let list = selectList.filter((v) =>
        selectList.every((a) =>  a.parentId != v[this.params.id])
      );
      return list
      }
    },
    placeholder(){
        if(this.keys == 'location'){
            return this.place
        }else if(this.keys == 'interest'){
            return this.checkedList.length?`已选择${this.checkedList.length}个兴趣词`:this.place
        }else if(this.keys == 'video'){
            return this.checkedList.length?`已选择${this.checkedList.length}个分类`:this.place
        }else if(this.keys == 'model'){
            return this.checkedList.length?`已选择${this.checkedList.length}种设备机型`:this.place
        }
    },
    searchList(){
      let keyword = this.indCodeName;
      let data = this.treeToArr(this.data);
      let list = []
      data.map(v=>{
        if(v[this.params.name].indexOf(keyword)>-1){
          list.push(v);
        }
      })
      return list
    }
  },
  methods: {
    filterText(val) {
      if(val){
        this.visible = false;
        
      }
      this.$refs.tree.filter(val);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.params.name].indexOf(value) !== -1;
    },
    selectChange(data, checkedNodes) {
      this.addKey(data);
      // this.checkedNodes = JSON.parse(JSON.stringify(checkedNodes.checkedNodes));
      // this.checkChange();
    },
    // 清除所有
    clearLocation(){
      this.$refs.tree.setCheckedNodes([]);
      this.checkedNodes = [];
      this.checkChange();
    },
    // 删除单个
    deleLocations(tag){
      this.checkedNodes = this.checkedNodes.filter(v=>v[this.params.id] !== tag[this.params.id]);
      this.$refs.tree.setChecked(tag[this.params.id],false,true);
      this.checkedNodes = this.$refs.tree.getCheckedNodes();
      this.checkChange();
    },
    addKey(data){
      console.log(this.data);
      let list = JSON.parse(JSON.stringify(this.checkedNodes));
      if(this.checkedNodes.filter(v=>v[this.params.id] == data[this.params.id]).length){
        this.checkedNodes = this.checkedNodes.filter(v=>v[this.params.id] != data[this.params.id])
      }else{
        this.checkedNodes.push(data)
      }
      if(data.parentId){
        this.checkedNodes = this.checkedNodes.filter(v=>(v[this.params.id] != data.parentId) && !((!v.parentId) && (data.platform == v.platform) && (data.deviceType == v.deviceType)))
      }else{
        this.checkedNodes = this.checkedNodes.filter(v=>(!((data.platform == v.platform) && (data.deviceType == v.deviceType) && v.parentId)))
      }
      this.$refs.tree.setCheckedKeys(this.checkedNodes.map(v=>v[this.params.id]));
      this.checkChange();
    },

    // 点击节点
    clickNode(data,node){
      let list = JSON.parse(JSON.stringify(this.checkedNodes));
      if(this.checkedNodes.filter(v=>v[this.params.id] == data[this.params.id]).length){
        this.checkedNodes = this.checkedNodes.filter(v=>v[this.params.id] != data[this.params.id])
      }else{
        this.checkedNodes.push(data)
      }
      this.$refs.tree.setCheckedKeys(this.checkedNodes.map(v=>v[this.params.id]));
      this.checkChange();
    },
    checkChange(){
      let selectList = this.checkedNodes.map((v) => {
        return { id: v[this.params.id], name: v[this.params.name], parentId: v.parentId,children:v[this.params.children],code:v.code};
      });
      let list = selectList.filter((v) =>
        selectList.every((a) => a[this.params.id] != v.parentId)
      );
      this.$emit('setList',list.map(v=>v.id))
    },
    treeToArr(tree){
      let arr = [];
      const expanded = datas=>{
        if(datas && datas.length){
          datas.forEach(e=>{
            arr.push(e)
            expanded(e[this.params.children])
          })
        }
      }
      expanded(tree);
      return arr
    },
    addParentId(list,id,code){
      list.map(v=>{
        v.parentId = id;
        v.code = code || v.code;
        if(v[this.params.children]){
          this.addParentId(v[this.params.children],v[this.params.id],v.code)
        }
      })
      return list
    }
  },
  watch:{
    listDefault:{
      handler(){
        this.$refs.tree.setCheckedKeys([...this.listDefault])
        this.$nextTick(()=>{
            this.checkedNodes = this.$refs.tree.getCheckedNodes();
            // this.checkChange();
        })
        
      },
      deep:true,
    //   immediate:true
    }
  },
  mounted() {
    // let list = JSON.parse(JSON.stringify(interest.data.ad_interest_list));
    // this.interest = this.addParentId(list,0);
    if(this.listDefault){
      this.$refs.tree.setCheckedKeys(this.listDefault)
      this.checkedNodes = this.$refs.tree.getCheckedNodes();
    }
    
    // this.getLocations();
  },
};
</script>
<style lang="scss">
.interesting{
//   padding: 20px 0 0 20px;
}
.el-popover {
  max-height: 400px;
  overflow-y: scroll;
}
.searchList{
  p{
    line-height: 2;
    cursor: pointer;
  }
}
.locations{
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  .el-button{
    position: absolute;
    top: -30px;
    right: 0;
  }
  .el-tag{
    margin: 5px;
  }
}
</style>