<template>
    <div>
        <el-drawer title="命名设置"  
            :visible="nameConfigShow" 
            direction="rtl" 
            @close="close" 
            wrapperClosable 
            size="1200px"
            :append-to-body="true"
            :modal-append-to-body="false">
            <div class="drawerCon">
                <div class="campainCon">
                    <el-form v-model="settingParams" label-position="top">
                        <el-form-item label="广告系列命名规则">
                            <el-select v-model="settingParams.camplain.val" multiple placeholder="请选择" style="width:60%">
                                <el-option
                                v-for="item in dictsInit.campaign"
                                :key="item.label+Math.random(0,1)"
                                :label="item.label"
                                :value="item.label">
                                </el-option>
                            </el-select>
                            <el-select v-model="settingParams.camplain.symbol" placeholder="请选择"  style="width:60px">
                                <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index+Math.random(1,2)"></el-option>
                            </el-select>
                            <p v-if="dictsInit.campaign">
                                <span v-for="item in dictsInit.campaign.filter(v=>settingParams.camplain.val.every(k=>k!=v.label))" :key="item.label+Math.random(0,1)" @click="quickSelect('camplain',item.label)">{{item.label}}</span>
                            </p>
                        </el-form-item>
                        <el-form-item label="广告组命名规则">
                            <el-select v-model="settingParams.adset.val" multiple placeholder="请选择" style="width:60%">
                                <el-option
                                v-for="item in dictsInit.adset"
                                :key="item.label+Math.random(0,1)"
                                :label="item.label"
                                :value="item.label">
                                </el-option>
                            </el-select>
                            <el-select v-model="settingParams.adset.symbol" placeholder="请选择"  style="width:60px">
                                <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                            </el-select>
                            <p v-if="dictsInit.adset">
                                <span v-for="item in dictsInit.adset.filter(v=>settingParams.adset.val.every(k=>k!=v.label))" :key="item.value+Math.random(0,1)" @click="quickSelect('adset',item.label)">{{item.label}}</span>
                            </p>
                        </el-form-item>
                        <el-form-item label="广告命名规则">
                            <el-select v-model="settingParams.ads.val" multiple placeholder="请选择" style="width:60%">
                                <el-option
                                v-for="item in  dictsInit.ad"
                                :key="item.label+Math.random(0,1)"
                                :label="item.label"
                                :value="item.label">
                                </el-option>
                            </el-select>
                            <el-select v-model="settingParams.ads.symbol" placeholder="请选择"  style="width:60px">
                                <el-option :label="symbol.key" :value="symbol.val" v-for="(symbol,index) in symbolList" :key="index"></el-option>
                            </el-select>
                            <p v-if="dictsInit.ad">
                                <span v-for="item in  dictsInit.ad.filter(v=>settingParams.ads.val.every(k=>k!=v.label))" :key="item.value+Math.random(0,1)" @click="quickSelect('ads',item.label)">{{item.label}}</span>
                            </p>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="demo-drawer__footer">
                <p>
                    <el-checkbox v-model="saveMine">保存为广告账号默认命名规则 
                        <el-tooltip class="item" effect="dark" content="保存为广告账号默认命名规则，下次该广告账号创建广告系列时，系统默认使用此命名规则。你可以在广告资产中分别对各广告账号设置命名规则。" placement="top-start"><i class="el-icon-warning-outline"></i></el-tooltip>
                    </el-checkbox>
                </p>
                <div>
                    <el-button @click="close">取消</el-button>
                    <el-button type="primary" @click="sure">保存</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {getSetsByAccounts,getDicts,setContent} from '@/api/creatAd.js'
    export default {
        props:['nameConfigShow','dictsInit','fbAccountId','accountSet'],
        data() {
            return {
                activeName:'first',
                settingParams:{
                    camplain:{
                        val:[],
                        symbol:'_'
                    },
                    adset:{
                        val:[],
                        symbol:'_'
                    },
                    ads:{
                        val:[],
                        symbol:'_'
                    },
                },
                symbolList:[{key:'_',val:'_'},{key:'-',val:'-'},{key:' ',val:' '},{key:'/',val:'/'},{key:'#',val:'#'},{key:'*',val:'*'}],
                campainInfo:{

                },
                saveMine:false,
                initDatas:{}
            }
        },
        components:{
        },
        methods: {
            // 关闭弹窗
            close(){
                // 页面恢复用户未操作之前状态
                let val={...this.initDatas}
                this.setParams(val)    
                // 弹窗关闭
                this.$emit('close','nameConfigShow')
            },
            // 点击保存
            sure(){
                let ad_name = JSON.parse(JSON.stringify(this.settingParams.ads.val))
                let adset_name =JSON.parse(JSON.stringify(this.settingParams.adset.val))
                let campaign_name = JSON.parse(JSON.stringify(this.settingParams.camplain.val))
                ad_name.map((item,i)=>{
                    ad_name[i] = `{${item}}`
                })
                adset_name.map((item,i)=>{
                    adset_name[i] = `{${item}}`
                })
                campaign_name.map((item,i)=>{
                    campaign_name[i] = `{${item}}`
                })

                ad_name = ad_name.join(this.settingParams.ads.symbol)
                adset_name = adset_name.join(this.settingParams.adset.symbol)
                campaign_name = campaign_name.join(this.settingParams.camplain.symbol);
                if(!ad_name||!adset_name||!campaign_name){
                    this.$message({
                        type:'warning',
                        message:'命名规则不能为空'
                    })
                    return false;
                }
                let obj = {ad_name:ad_name,adset_name:adset_name,campaign_name:campaign_name}
                this.$emit('setName',obj)
                if(this.saveMine){
                    setContent({ad_name:ad_name,adset_name:adset_name,campaign_name:campaign_name,accounts:[this.fbAccountId]}).then(res=>{
                        if(res.code == 200){
                            this.$message({
                                type:'success',
                                message:'保存成功'
                            })
                        }
                    })
                }
                this.close();
            },
            // 参数快捷选项
            quickSelect(key,val){
                this.settingParams[key].val.push(val);
                this.$set(this.settingParams,key,this.settingParams[key])
            },
            createSetParams(target){
                let curSym= this.symbolList.find(item=>target.includes(item.key)),obj={}
                if(curSym){
                    obj = {
                        symbol:curSym.key,
                        val:target.split(curSym.key).map(item=>item.slice(1,item.length-1))
                    }
                }else{
                    obj= {
                        symbol:"_",
                        val:[target.slice(1,target.length-1)]
                    }
                }
                // console.log("obj-ly",obj)
                return obj;
            },
            setParams(val){
                this.$set(this.settingParams,'ads',this.createSetParams(val.ad_name))
                this.$set(this.settingParams,'adset',this.createSetParams(val.adset_name))
                this.$set(this.settingParams,'camplain',this.createSetParams(val.campaign_name))   
            }
        },
        watch: {
            settingParams:{
                handler(){
                    
                },
                deep:true
            },
            accountSet:{
                handler(val){
                    // console.log("accountSet-ly",val)  
                    this.initDatas=val?{...val}:{}
                    // console.log("this.initDatas",this.initDatas)
                    this.setParams(val)               
                    // console.log("this.settingParams-ly",this.settingParams)
                }
            }
        },
    }
</script>
<style lang="scss" scope>
    .campainCon{
        padding: 0 20px;
        .title{
            line-height: 40px;
            color: #666;
            font-size: 14px;
        }
        .paramsList{
            .paramsItem{
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .el-input,.el-select{
                    width:360px;
                }
                span{
                    margin: 0 5px;
                    cursor: pointer;
                }
                i{
                    margin-left: 10px;
                    font-size: 18px;
                    color: #666;
                    cursor: pointer;
                }
            }
        }
        .el-input--suffix .el-input__inner{
            padding-right: 0;
        }
        .el-form-item{
            p{
                font-size: 12px;
                color: #999;
                span{
                    margin: 0 5px;
                }
            }
        }
    }
</style>